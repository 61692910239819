
import { ComposedState } from '@/types';
import Vue from 'vue';

import { mapState } from 'vuex';

export default Vue.extend({
    name: 'ActiveUsers',
    
    computed: {
        ...mapState<ComposedState>({
            activeUsers: (state: ComposedState) => state.dashboard.activeUsers,
        }),
    },
});
