
import Vue from 'vue';

import { mapState } from 'vuex';

import FileDownload from 'js-file-download';

import dashboardServices from '@/store/modules/dashboard/services';
import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'CountryStatistic',

    data() {
        return {
            reportDateMenu: false,
            search: '',
            reportsFilter: false,

            snackbar: false,

            headers: [
                { text: 'Name', value: 'countryName', sortable: false },
                { text: 'Users', value: 'activeUsers' },
            ],

            toMaxDate: new Date().toISOString().substr(0, 10),
            toDate: new Date().toISOString().substr(0, 10),
            fromDate: '',
        };
    },

    computed: {
        ...mapState<ComposedState>({
            token: (state: ComposedState) => state.admin.access,
            countryStatistic: (state: ComposedState) => state.dashboard.countryStatistic,
        }),
    },

    mounted() {
        const date = new Date();
        date.setDate(date.getDate() - 29);
        this.fromDate = date.toISOString().substr(0, 10);
    },

    methods: {
        dateToString(date) {
            const year = date.getFullYear();
            const month
                = date.getMonth() + 1 < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;
            const day
                = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

            const dateStrign = `${year}${month}${day}`;

            return dateStrign;
        },

        async getReport() {
            const dateFrom = this.dateToString(new Date(this.fromDate));
            const dateTo = this.dateToString(new Date(this.toDate));

            if (!this.fromDate || !this.toDate) {
                this.snackbar = true;
            } else {
                await dashboardServices.exportDashboard(dateFrom, dateTo)
                    .then((response: any) => {
                        FileDownload(
                            response.data,
                            `report_${dateFrom}-${dateTo}.xls`,
                        );
                    });
            }
        },

        calcToMaxDate(from) {
            const date = new Date(from);
            date.setDate(date.getDate() + 29);

            this.toDate = '';

            this.toMaxDate = date.toISOString().substr(0, 10);
        },
    },
});
