
import Vue from 'vue';

import { mapActions } from 'vuex';

import { dashboardActions } from '@/store/modules/dashboard/names';

import ActiveUsers from './ActiveUsers.vue';
import CountryStatistic from './CountryStatistic.vue';

export default Vue.extend({
    name: 'Dashboard',

    components: {
        ActiveUsers,
        CountryStatistic,
    },

    async mounted() {
        // @ts-ignore
        await this.getDashboard();
    },

    methods: {
        ...mapActions({
            getDashboard: dashboardActions.getDashboard,
        }),
    },
});
